<template>
  <vx-card class="contained-example-container pie">
    <div class="header">
      <div class="row">
        <div>
          <h4>{{ title }}</h4>
        </div>
        <div>
          <div class="actions">
            <span class="mr-2" @click="download">
              <vs-icon
                v-tooltip="lang.metrics.download[languageSelected]"
                icon="icon-download"
                icon-pack="feather"
                style="cursor: pointer"
              ></vs-icon>
            </span>
          </div>
        </div>
      </div>
      <div>
        <h6 class="description">{{ description }}</h6>
      </div>
    </div>

    <div class="slice-filter pt-2">
      <multiselect
        v-if="originalLabels && originalLabels.length > 2"
        v-model="selectedSlices"
        :options="originalLabels"
        :searchable="true"
        :show-labels="false"
        :placeholder="lang.metrics.filterItems[languageSelected]"
        multiple
        @input="onSliceSelected"
      />
    </div>

    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <vue-apex-charts
        :key="key"
        type="pie"
        height="340"
        :options="options"
        :series="series"
      />
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState, mapActions } from 'vuex'
import { PALETTE } from '@/constants/constants'
const moment = require('moment')
const Json2csvParser = require('json2csv').Parser

export default {
  name: 'ValidationPieV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VueApexCharts,
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    others() {
      if (!this.report || !this.report._extra) {
        return 'others'
      }
      if (this.report._extra.others) {
        const found = this.report._extra.others.find(element => {
          return element.lang === this.languageSelected
        })
        if (found) {
          return found.text
        }
      }
      return 'others'
    }
  },
  data() {
    return {
      Json2csvParser,

      LIMIT: 10, // cantidad de elementos a mostrar
      key: new Date().getTime(),

      series: [],
      options: {
        labels: [],
        responsive: [
          {
            breakpoint: 1300,
            options: {
              chart: {
                width: 320
              }
            }
          },
          {
            breakpoint: 1024,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ],
        dataLabels: {
          formatter: function(val) {
            // hide <= 5% labels
            return val > 5 ? `${val.toFixed(1)}%` : ''
          }
        },
        colors: PALETTE
      },
      selectedSlices: [],
      originalLabels: [],
      originalSeries: []
    }
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    onSliceSelected() {
      // when no slices selected reset chart
      if (!this.selectedSlices.length) {
        this.load(this.report)
        return
      }

      // build chart only with selected slices
      this.options.labels = []
      this.series = []
      let othersCount = 0

      this.originalLabels.forEach((option, index) => {
        if (this.selectedSlices.indexOf(option) !== -1) {
          this.options.labels.push(option)
          this.series.push(this.originalSeries[index])
        } else {
          othersCount += this.originalSeries[index]
        }
      })

      if (othersCount > 0) {
        this.options.labels.push(
          this.lang.metrics.others[this.languageSelected]
        )
        this.series.push(othersCount)
      }

      // update chart
      this.key = new Date().getTime()
    },
    download() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }

      const total = this.series.reduce((acc, elem) => acc + elem, 0)

      let fields = ['Item', '#', '%']
      let data = []
      this.options.labels.forEach((intent, index) => {
        data.push({
          Item: intent,
          '#': this.series[index],
          '%': total
            ? ((parseInt(this.series[index]) * 100) / total).toFixed(2)
            : 'N/A'
        })
      })

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_metric_${this.title}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    },
    async load(report) {
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        this.options.labels = [
          this.lang.metrics.validationPie.positive[this.languageSelected],
          this.lang.metrics.validationPie.negative[this.languageSelected],
          this.lang.metrics.validationPie.noAnswer[this.languageSelected]
        ]
        this.series = [
          parseInt(result.data[0][0].positive),
          parseInt(result.data[0][0].negative),
          parseInt(result.data[0][0].noresponse)
        ]
        this.key = new Date().getTime() // para actualizar el componente
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
        this.originalLabels = this.options.labels
        this.originalSeries = this.series
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.pie {
  .vx-card__body {
    display: flex;
    flex-direction: column;

    .slice-filter {
      display: flex;
      align-self: center;

      .multiselect {
        min-width: 300px;
        .multiselect__tags {
          width: 100%;
        }
      }
    }
    .apexcharts-legend {
      justify-content: center;
      max-width: 160px;
    }
  }
}
</style>
